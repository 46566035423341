import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getCurrentRequest, getSpecializations } from '../../actions/common'
import styles from './styles.module.scss'
import Loader from '../../components/Loader'
import { BACKEND_DOMAIN, statuses } from '../../assets/const'
import { setExpertGrade } from '../../actions/expert'
import { Col, Form, Row, Table } from 'react-bootstrap'
import FileLabel from '../../components/FileLabel/FileLabel'
import { strip } from '../../utils/smallHelpers'
import { getOptions } from '../../utils/getOptions'
import Button from '../../components/Button'
import FileInput from '../../components/FileInput/FileInput'
import moment from 'moment'
import cn from 'classnames'
import { EditModal, PDFPrint, ComissionBlock } from './Components'
import { finishRequest, updateRequestFiles, updateRequestStatus } from '../../actions/teacher'
import GoBack from '../../components/GoBack'
import Confirmation from '../../components/Confirmation/Confirmation'
import parse from "html-react-parser";
import ExpertComissionBlock from './Components/ExpertComissionBlock'
import ExpertPDFPrint from './Components/ExpertPDFPrint'
import { IoIosReturnLeft } from "react-icons/io";
import { AddFileModal } from '../../components/AddFileModal'
import AwardsBlock from '../../components/AwardsBlock'


const imageReg = /\.(jpg|jpeg|png|gif|bmp)$/i

export default function Request () {
  
  const { requestId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentRequest, requestParts, criterions, lessons, userInfo, comission, specializations, loading } = useSelector(state => state.common)
  const {loading: gradeLoading} = useSelector(state => state.expert)
  const { expert, edu_expert, organizations, comission: comissionInfo } = useSelector(state => state.auth)

  const {
    expert_files_json: existFiles,
    additional_files_json: profileFiles,
    request_files_json,
    org_id,
    fio,
    position,
    category,
    cert_year,
    cert_result,
    current_category,
    current_cert_date,
    status,
    created,
    conduct_date,
    current_position,
    cert_reason,
    position_exp,
    edu_exp,
    org_exp,
    simple_cert,
    awards,
    responsible_json
  } = currentRequest || {}

  const { academic_degree, academic_title, ped_qual } = userInfo || {}

  const statusText = statuses.find(stat => stat.value === status)?.name
  const experts = expert || edu_expert
  const teacherCanEdit = !expert && !edu_expert && (status === 1 || status === 5) 
  const criterionsLength = criterions?.reduce((acc, crit) => acc + crit.criterions.reduce((acc, crit) => acc + (crit.child_criterion_json.length || 0) + 1, 0), 0)
  const teacherCantFinish = criterionsLength > requestParts?.length

  const [expertData, setExpertData] = useState({status, cert_result})
  const [files, setFiles] = useState(existFiles ? [...existFiles] : [])
  const [responsible, setResponsible] = useState(responsible_json || [])
  const [finishRequestConfirm, setFinishRequestConfirm] = useState(false)
  const [requestFiles, setRequestFiles] = useState(request_files_json || [])
  const [localAwards, setLocalAwards] = useState(awards || [])

  useEffect(() => {
    dispatch(getCurrentRequest(requestId))
    !specializations && dispatch(getSpecializations())
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [])
  
  useEffect(() => {
    if (!currentRequest) return;
    
    setExpertData({status, cert_result})
    setResponsible(responsible_json || [])
    setRequestFiles(request_files_json || [])
    setLocalAwards(awards || [])
  }, [currentRequest])

  const closeResume = async () => {
    const filesList = [...requestFiles, ...localAwards]
    !experts && await dispatch(updateRequestFiles(requestId, filesList))
    navigate('/')
  }

  const saveButtonClick = async () => {
    if (expert) {
      const response = await dispatch(setExpertGrade(requestId, expertData, files))
      response && closeResume()
    } else {
      setFinishRequestConfirm(true)
    }
  }

  const acceptFinishConfirm = async () => {
    setFinishRequestConfirm(false)
    await dispatch(finishRequest({responsible, requestFiles}))
    // await dispatch(getCurrentRequest(requestId))
    closeResume()
  }
    
  const rejectFinishConfirm = () => {
    setFinishRequestConfirm(false)
  }

  const expertInputChanges = (e) => {
    const {name, value} = e.target
    setExpertData(prev => ({...prev, [name]: value}))
  }

  const handleAddFile = (e) => {
    const { files } = e.target
    const filesList = Object.values(files)
    const newFilesValue = filesList.length
      ? [filesList[0]]
      : []
    setFiles(newFilesValue)
  }

  const deleteFileHandler = (index) => () => {
    setRequestFiles(prev => prev.filter((file, i) => i !== index))
  }

  const onAddFile = (fileObject) => {
    const { user_filename, entity_type, file } = fileObject
    const _file = {file: file[0], user_filename, entity_type}
    setRequestFiles(prev => [...prev, _file])
  }

  const renderFile = (fileType) => (filesList, file, index) => {
    if (file.entity_type === fileType) {
      const {user_filename, filepath, file_size, created_at, file_id} = file
      const filename = file.filename || file.file.name
      const fileSize = file_size ? `${file_size} Мб` : null
      const createdAt = created_at ? moment(created_at).format('DD.MM.YYYY') : null
      const fileLink = file_id ? `${BACKEND_DOMAIN||''}/${filepath}/${filename}` : URL.createObjectURL(file.file)
      const fileElement = (
        <div className={styles.fileItem}>
          <FileLabel
            key={filename}
            fileName={user_filename}
            imageMode={imageReg.test(filename)}
            pdfMode={filename.includes('pdf')}
            fileLink={fileLink}
            onDelete={teacherCanEdit && deleteFileHandler(index)}
          />
          <p>{createdAt}</p>
          <p>{fileSize}</p>
        </div>
      )
      return [...filesList, fileElement];
    } else {
      return filesList;
    } 
  }


  const renderFiles = (fileType) => (
    <div className={styles.teacherFilesBlock}>
      {requestFiles.reduce(renderFile(fileType), [])}
      <AddFileModal
        entity_type={fileType}
        onAddFile={onAddFile}
        disabled={!teacherCanEdit}
      />
    </div>
  )

  const renderCriterionsGroup = ({name, criterions}, index) => {
    const groupName = `${index+1}. ${name}`
    return (
      <>
        <tr>
          <td className={styles.titleTd} colSpan={8}><b>{groupName}</b></td>
        </tr>
        {criterions.map(renderCriterion())}
      </>
    )
  }

  const renderCriterion = (documentsDesciption) => (criterion, ind) => {
    const {id, name, documents, points, level_json, child_criterion_json} = criterion
    const currentCriterion = requestParts.find(part => part.criteria === id)
    const emptyCriterion = { name, criteria: id }
    const childCriterionsIds = child_criterion_json?.map(({id}) => id)
    const childParts = requestParts.filter(({criteria}) => childCriterionsIds?.includes(criteria))
    return (
      <>
        
        <Criterion
          key={ind}
          index={ind}
          data={currentCriterion || emptyCriterion}
          criteriaName={name}
          criteriaDocuments={documents || documentsDesciption}
          validPoints={points}
          criteriaLevels={level_json}
          empty={!currentCriterion}
          teacherCanEdit={teacherCanEdit}
          expertMode={expert || edu_expert}
          subCriterion={!child_criterion_json}
          haveChildren={!!child_criterion_json?.length}
          childCriterions={child_criterion_json}
          childParts={childParts}
          draft={status === 1}
        />
        {child_criterion_json?.map(renderCriterion(documents))}
      </>
    )
  }

  const formattedDate = (date) => date ? moment(date).format('DD.MM.YYYY HH.mm') : null
  
  const renderPedQualRow = ({name, year, hours}) => {
    const yearOnly = year ? moment(year).year() : '-'
    return (
    
      <tr key={name}>
        <td>{name}</td>
        <td>{yearOnly}</td>
        <td style={{textAlign: 'center'}}>{hours}</td>
      </tr>
    )
  }

  const totalPoints = (fieldName) => requestParts?.reduce((acc, part) => acc + (part[fieldName] || 0), 0)

  const returnRequestHandler = () => {
    dispatch(updateRequestStatus(requestId, 1))
  }

  const pedQualTable = (
    <Table>
      <thead>
        <tr>
          <th>Название</th>
          <th>Год</th>
          <th style={{width: '155px'}}>Количество часов</th>
        </tr>
      </thead>
      <tbody>
        {ped_qual?.length
          ? ped_qual.map(renderPedQualRow)
          : <tr className={styles.noDataRow}>
              <td colSpan={3}>Данные отсутствуют</td>
            </tr>
        }
      </tbody>
    </Table>
  )

  const htmlParse = (html) => {
    const reactElement = html && parse(html);
    return reactElement;
  };

  const organization = organizations?.find(({idOrganization}) => idOrganization == org_id)?.OrgName


  const renderPDFCriterion = ({name, child_criterion_json, id}) => {
    const part = requestParts?.find(part => part.criteria === id)
    const { points, commission_points, description, documents } = part || {}
    return (
      <>
        <tr>
          <td>{name}</td>
          {!simple_cert && <td>{commission_points}</td>}
          {!simple_cert && <td>{points}</td>}
          <td>{parse(documents || '')}</td>
          <td>{parse(description || '')}</td>
        </tr>
        {child_criterion_json?.map(renderPDFCriterion)}
      </>
    )
  }

  const tableForPdf = (
    <table id='criterionsTable' hidden>
      <thead>
        <tr>
          <th>Критерии и показатели</th>
          {!simple_cert && <th>Баллы комиссии ОО</th>}
          {!simple_cert && <th>Баллы эксперта</th>}
          <th>Наличие подтверждающих документов в портфолио</th>
          <th>Примечание</th>
        </tr>
      </thead>
      <tbody>
        {criterions?.map(({name, criterions}, index) => {
          const groupName = `${index+1}. ${name}`
          const colSpan = simple_cert ? 3 : 5
          return (
            <>
              <tr>
                <td className={styles.titleTd} colSpan={colSpan}><b>{groupName}</b></td>
              </tr>
              {criterions.map(renderPDFCriterion)}
            </>
        )})}
        <tr>
          <td>Общее количество баллов</td>
          {!simple_cert && <td><b>{totalPoints('commission_points')}</b></td>}
          {!simple_cert && <td><b>{totalPoints('points')}</b></td>}
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  )

  const deleteAwardHandler = (name, index) => () => {
    setLocalAwards(prev => prev.filter((award, i) => i !== index))
  }

  const addAwardHandler = (award) => {
    const { entity_type: name, file } = award
    const _file = file[0]
    const newItem = {...award, file: _file}
    const awardsList = [...localAwards, newItem]
    setLocalAwards(awardsList)
  }
  

  const haveAcceptedAward = localAwards?.some(award => !!award.status)
  const returnButtonDisabled =  [6, 7].includes(status) || totalPoints('commission_points') > 0 || totalPoints('points') > 0 || haveAcceptedAward


  return (
    <div className={styles.container}>
      {!loading && <GoBack/>}
      {!loading && currentRequest &&
        <div className={styles.rightButtonsBlock}>
          {expert
            ? <ExpertPDFPrint
                data={currentRequest}
                organization={organization}
                specializations={specializations}
                comission={comission.values}
                totalPoints={totalPoints('points')}
                comissionInfo={comissionInfo}
              />
            : <PDFPrint
                data={currentRequest}
                organization={organization}
                specializations={specializations}
                comission={comission.list}
                totalPoints={totalPoints('points')}
                comissionInfo={comissionInfo}
              />
          }
          {!experts &&
            <Button
              label='Отозвать'
              title={returnButtonDisabled ? 'Заявка принята в обработку' : 'Отозвать заявку'}
              disabled={returnButtonDisabled}
              onClick={returnRequestHandler}
            />
          }
        </div>
      }
      {
        loading
          ? <Loader/>
          : currentRequest &&
              <div className={styles.requestWrapper}>
                {/* <Button label='Назад' onClick={closeResume}/> */}
                <Row className={cn(styles.stageRow)}>
                  <h3>Заявление</h3>
                  <ul>
                    <li><b>Образовательная организация:</b> {organization}</li>
                    <li><b>ФИО:</b> {fio}</li>
                    <li><b>Должность педагогического работника для аттестации:</b> {position}</li>
                    <li><b>Квалификационная категория для аттестации:</b> {category}</li>
                    <li><b>Дата последней аттестации:</b> {current_cert_date}</li>
                    <li><b>Текущая категория:</b> {current_category}</li>
                    <li><b>Занимаемая должность:</b> {current_position}</li>
                    <li><b>Основание для аттестации:</b> {htmlParse(cert_reason)}</li>
                    <li><b>Стаж в данной должности:</b> {position_exp}</li>
                    <li><b>Стаж педагогической работы:</b> {edu_exp}</li>
                    <li><b>Стаж в данном учреждении:</b> {org_exp}</li>
                    <li><b>Ученая степень:</b> {academic_degree}</li>
                    <li><b>Ученое звание:</b> {academic_title}</li>
                    {/* <li><b>Награды:</b> {awardsTable}</li> */}
                    <li><b>Сведения о повышении квалификации:</b> {pedQualTable}</li>
                    {/* {renderDescriptionLi()} */}
                    {/* {renderStatusLi()} */}
                    <li><b>Скан копия заявления:</b> {renderFiles('cert_request_scan')}</li>
                    <li><b>Общие сведения о педагоге:</b> {renderFiles('cert_request_common')}</li>
                    {/* <li><b>Портфолио:</b> {renderFiles('third')}</li> */}
                    <li><b>Дата отправки:</b> {formattedDate(created)}</li>
                    {/* <li><b>Экспертное заключение (файл):</b> {renderFilesField()}</li> */}
                    {/* <li><b>Дата проведения экспертизы:</b> {formattedDate(conduct_date)}</li> */}
                  </ul>
                </Row>
                <Row className={['mt-3', styles.stageRow]}>
                  <h3>Критерии</h3>
                  <b>(все подтверждающие документы в соответствии с требованиями экспертного заключения по занимаемой должности загружать в полном объеме)</b>
                  {tableForPdf}
                  {
                    loading
                      ? <Loader/>
                      : !!requestParts &&
                          <Table className={styles.resumeTable} striped>
                            <thead>
                              <tr>
                                <th>Критерии и показатели</th>
                                <th>Файлы</th>
                                <th>Баллы комиссии ОО</th>
                                <th>Баллы эксперта</th>
                                <th>Наличие подтверждающих документов в портфолио</th>
                                <th>Примечание (комиссия ОО)</th>
                                <th>Примечание (эксперт)</th>
                                <th style={{width: '125px'}}>Статус (эксперт)</th>
                                {/* {!expert && teacherCanEdit && <th/>} */}
                              </tr>
                            </thead>
                            <tbody>
                              {criterions?.map(renderCriterionsGroup)}
                              <tr>
                                <td><b>Общее количество баллов:</b></td>
                                <td><b>{totalPoints('commission_points')}</b></td>
                                <td><b>{totalPoints('points')}</b></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </Table>
                  }
                </Row>

                {!!simple_cert &&
                  <div className={styles.responsibleBlock}>
                    <p>Награды:</p>
                    <AwardsBlock
                      files={localAwards}
                      expertsMode={expert}
                      disabled={!teacherCanEdit}
                      onDeleteFile={deleteAwardHandler}
                      onAddFile={addAwardHandler}
                      statusCanChanged={status !== 6 && status !== 7}
                    />
                  </div>
                }

                {experts &&
                  <div className={styles.responsibleBlock}>
                    <p>{edu_expert ? 'Члены комиссии ОО' : 'Подписи экспертов'}</p>
                    {
                      edu_expert
                        ? <ComissionBlock requestId={requestId} />
                        : <ExpertComissionBlock requestId={requestId} />
                    }
                  </div>
                }
                
                {finishRequestConfirm &&
                  <Confirmation
                    title='Вы уверены что хотите отправить заявление?'
                    acceptConfirmation={acceptFinishConfirm}
                    rejectConfirmation={rejectFinishConfirm}
                  />
                }

                <div className={styles.buttonsBlock}>
                  {(teacherCanEdit) &&
                    <Button
                      onClick={saveButtonClick}
                      submitting={gradeLoading}
                      // disabled={!expert && teacherCantFinish}
                      label={expert ? 'Утвердить' : 'Подписать и отправить'}
                    />
                  }
                  {expert
                    ? <Button
                        style={{padding: '0', minWidth: '50px'}}
                        onClick={closeResume}
                      >
                        <IoIosReturnLeft size={30}/>
                      </Button>
                    : <Button
                        label={'Сохранить и выйти'}
                        onClick={closeResume}
                      />
                  }
                </div>
              </div>
      }
    </div>
  )
}


const Criterion = ({
  data,
  index,
  criteriaName,
  criteriaDocuments,
  criteriaLevels,
  validPoints,
  teacherCanEdit,
  empty,
  expertMode,
  subCriterion,
  haveChildren,
  childParts,
  draft
}) => {
  
  const [editModal, setEditModal] = useState(false)

  const {documents, description, status, id, expert_comment, result_comment, points, commission_points, files_json: files} = data

  const statusText = haveChildren ? '' : empty ? 'Не заполнено' : statuses.find(stat => stat.value == data.status)?.name

  // const neededStatuses = typeof status !== 'number' || status === 3 || status === 5
  
  const openEditModal = () => {
    if ((expertMode && empty) || haveChildren) return;
    setEditModal(true)
  }

  const closeEditModal = () => {
    setEditModal(false)
  }
  
  const criterionNameStyle = {paddingLeft: subCriterion ? '40px' : '0'}

  const sumPoints = haveChildren ? childParts.reduce((sum, criterion) => sum + (parseInt(criterion.points) || 0), 0) || null : points
  const sumComissionPoints = haveChildren ? childParts.reduce((sum, criterion) => sum + (parseInt(criterion.commission_points) || 0), 0) || null : commission_points

  const pointsBG = sumPoints && sumComissionPoints
            ? sumPoints >= sumComissionPoints
                ? '#84fd84'
                : '#ff748c'
            : 'none'

            
  const renderFile = (file) => {
    const {filename, filepath} = file
    const fileLink = `${BACKEND_DOMAIN||''}/${filepath}/${filename}`
    return (
      <FileLabel
        fileName={filename}
        fileLink={fileLink}
      />
    )
  }


  return (
    <>
      <tr key={index} onClick={openEditModal} className={cn({[styles.criterionWithChild]: haveChildren})}>
        <td style={criterionNameStyle}>{criteriaName}</td>
        <td>
          <div>{files?.map(renderFile)}</div>
        </td>
        <td>{sumComissionPoints}</td>
        <td style={{backgroundColor: pointsBG}}>{sumPoints}</td>
        <td>{parse(documents || '')}</td>
        <td>{expert_comment}</td>
        <td>{result_comment}</td>
        <td style={{color: empty ? 'red' : 'black'}}>{statusText}</td>
      </tr>
      <EditModal
        open={editModal}
        onClose={closeEditModal}
        addMode={!data.id}
        teacherCanEdit={teacherCanEdit}
        validPoints={validPoints}
        data={data}
        draft={draft}
        criteriaName={criteriaName}
        criteriaDocuments={criteriaDocuments}
        criteriaLevels={criteriaLevels}
      />
    </>
  )
}