import React, { useEffect, useState } from 'react'
import styles from './SharedFiles.module.css'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { getLessons, getBank, getBankCount } from '../../actions/common'
import { getOrganizations } from '../../actions/auth'
import Search from './Search/Search'
import Table from './Table/Table'
import Loader from '../../components/Loader/index'
import PaginationBlock from './Pagination/Pagination'
import GoBack from '../../components/GoBack'

const SharedFiles = () => {
    
    const [activePage, setActivePage] = useState(0); // активная страница

    const dispatch = useDispatch();
    
    useEffect(() => {
        !organizations && dispatch(getOrganizations());
    }, []);
    

    const { organizations, org } = useSelector(state => state.auth);
    const { lessons } = useSelector(state => state.common);
    const { bank, count, loading } = useSelector(state => state.bank);

    const {
          values,
          errors,
          handleChange,
          handleSubmit,
          setValues
    } = useFormik({
        initialValues: {
            lesson_id: '',
            parallel: '',
            year: '',
            filename: '',
            organization: org,
            sort: 'filename'
        },
        enableReinitialize: true,
        onSubmit: values => filesRequest(values, activePage)
    })

    useEffect(() => {
        values.organization && dispatch(getLessons(values.organization));
    }, [values.organization])

    const filesRequest = (values , activePage) => {
        dispatch(getBank(activePage, 10, values));
        dispatch(getBankCount(activePage, 10, values));    
    }
        

    const handleSetActivePage = (number) => {
        setActivePage(number);
        filesRequest(values, number);
    }

    return (
    <div className={styles.wrapper}>
        <GoBack/>
        <h2 className={styles.title}>Методическая копилка</h2>
            <Search
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            organizations={organizations}
            lessons={lessons}/>

            {loading ? <Loader /> : <Table lessons={lessons} list={bank} filesRequest={filesRequest} values={values} setValues={setValues} activePage={activePage}/>}

            <PaginationBlock handleSetActivePage={handleSetActivePage} activePage={activePage} count={count}/>
    </div>
  )
}

export default SharedFiles