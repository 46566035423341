import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { Form, Row, Table } from 'react-bootstrap'
import Button from '../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import FileInput from '../FileInput/FileInput'
import { BACKEND_DOMAIN, parallels } from '../../assets/const'
import { IoMdClose } from "react-icons/io";
import cn from 'classnames'
import { getFiles, getLessons, getTopics, removeFiles } from '../../actions/common'
import Loader from '../Loader'
import { FaFile } from 'react-icons/fa';


export default function FileBlock ({filesList, addFile, onDelete, choiceButtonVisible, disabled}) {
  
  const dispatch = useDispatch()
  const { lessons, topics } = useSelector(state => state.common)

  const [openAddModal, setOpenAddModal] = useState(false)
  const [openChoiceModal, setOpenChoiceModal] = useState(false)


  useEffect(() => {
    !lessons && dispatch(getLessons())
    !topics && dispatch(getTopics())
  }, [])
  

  const fileClick = (file) => (e) => {
      if (!file.id) return;
      const link = document.createElement('a')
      const {filename, filepath} = file
      const fileLink = `${BACKEND_DOMAIN || ''}/${filepath}/${filename}`
      link.setAttribute('href', fileLink)
      link.setAttribute('target', 'blank')
      link.setAttribute('rel', 'noopener noreferrer')
      link.setAttribute('download', filename)
      link.click()
  }

  const renderFile = (file, index) => {
    
    const { filepath, filename, lesson_id, parallel } = file
    const lessonName = lessons?.find(({LID}) => LID == lesson_id)?.name

    const loaded = !!file.id

    return (
      <tr className={cn({[styles.loadedFile]: loaded})} onClick={fileClick(file)}>
        <td>{filename}</td>
        <td>{lessonName}</td>
        <td>{parallel}</td>
        {!disabled &&
          <td onClick={handleDeleteFile(index)}>
            <IoMdClose color='red' size={20}/>
          </td>
        }
      </tr>
    )
  }
  
  const handleOpenModal = () => setOpenAddModal(true)
  const handleCloseModal = () => setOpenAddModal(false)
  
  const handleOpenChoiceModal = () => setOpenChoiceModal(true)
  const handleCloseChoiceModal = () => setOpenChoiceModal(false)

  const handleDeleteFile = (index) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    onDelete('files', index)
  }



  return (
    <div className={styles.wrapper}>
      <AddModal
        open={openAddModal}
        closeModal={handleCloseModal}
        lessons={lessons}
        addFile={addFile}
      />
      <ChoiceModal
        open={openChoiceModal}
        closeModal={handleCloseChoiceModal}
        topics={topics}
        addFile={addFile}
      />
      <p>Файлы (привязка нескольких файлов):</p>
      {filesList?.length
        ? <Table>
            <thead>
              <tr>
                <th>Имя файла</th>
                <th>Предмет</th>
                <th>Параллель</th>
                {!disabled && <th></th>}
              </tr>
            </thead>
            <tbody>
              {filesList.map(renderFile)}
            </tbody>
          </Table>
        : <p className={styles.noFiles}>Файлы отсутствуют</p>
      }

      <div className={styles.buttonsBlock}>
        {!disabled && <Button type='button' label='Добавить' onClick={handleOpenModal} />}
        {!disabled && choiceButtonVisible && <Button type='button' label='Выбрать файл из LMS' onClick={handleOpenChoiceModal} />}
      </div>
      
    </div>
  )
}

const AddModal = ({ lessons, open, closeModal, addFile }) => {

  const [checkboxes, setCheckboxes] = useState({lesson_id: false, parallel: false})

  useEffect(() => {
    open && handleReset()
  }, [open])
  

  const {
    values,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue
  } = useFormik({
    initialValues: {
      lesson_id: '',
      parallel: '',
      file: null
    },
    enableReinitialize: true,
    onSubmit: values => {
      addFile(values)
      closeModal()
    }
  })  
  
  const renderLessonOption = ({LID, name}) => <option key={LID} value={LID}>{name}</option>
  
  const renderParallelOption = (num) => <option key={num} value={num}>{num}</option>

  const handleAddFile = (e) => {
    const { name, files } = e.target
    const file = Object.values(files)
    setFieldValue(name, file)
  }

  const checkboxClick = (e) => {
    const { name, checked } = e.target
    setFieldValue(name, '')
    setCheckboxes(prev => ({...prev, [name]: checked}))
  }


  return (
    <Modal
      open={open}
      onClose={closeModal}
      animationDuration={1}
      styles={{modal: {minWidth: '350px'}}}
    >
      <Form className='mt-3'>
        <Row className='mb-3'>
          <Form.Label className={styles.formLabels}>Выберите файл:</Form.Label>
          <FileInput
            height='300px'
            name='file'
            files={values.file}
            onChange={handleAddFile}
          />
        </Row>
        <Row className='mb-3'>
          <Form.Group>
            <Form.Label className={styles.formLabels}>Выберите дисциплину:</Form.Label>
            <Form.Check
              label='Без дисциплины'
              name='lesson_id'
              onClick={checkboxClick}
              checked={checkboxes.lesson_id}
            />
            <Form.Select
              name='lesson_id'
              value={values.lesson_id}
              onChange={handleChange}
              disabled={checkboxes.lesson_id}
            >
              <option value=''>Выбрать</option>
              {lessons?.map(renderLessonOption)}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className='mb-3'>
          <Form.Group>
            <Form.Label className={styles.formLabels}>Выберите параллель:</Form.Label>
            <Form.Check
              label='Без параллели'
              name='parallel'
              onClick={checkboxClick}
              checked={checkboxes.parallel}
            />
            <Form.Select
              name='parallel'
              value={values.parallel}
              onChange={handleChange}
              disabled={checkboxes.parallel}
            >
              <option value=''>Выбрать</option>
              {parallels?.map(renderParallelOption)}
            </Form.Select>
          </Form.Group>
        </Row>
        <Button onClick={handleSubmit} label='Добавить'/>
      </Form>
    </Modal>
  )
}

const ChoiceModal = ({ open, closeModal, topics, addFile }) => {

  const dispatch = useDispatch()
  const { files } = useSelector(state => state.common)
  const { loading } = useSelector(state => state.teacher)

  const [topic, setTopic] = useState('')

  useEffect(() => {
    dispatch(removeFiles())
    setTopic('')
  }, [open])
  

  const renderTopicOption = ({sheid, course, subject }) => {
    const title = `${course} - ${subject}`
    return <option key={sheid} value={sheid}>{title}</option>
  }

  const getFilesFunc = ({ week_id, sheid }) => {
    const params = {week: week_id, schedule: sheid}
    dispatch(getFiles(params))
  }

  const handleChangeTopic = (e) => {
    const { value } = e.target
    setTopic(value)

    if (!value) {
      dispatch(removeFiles())
      return
    }

    const topic = topics.find(({sheid}) => sheid == value)
    getFilesFunc(topic)
  } 

  const renderFile = (file, index) => {
    return (
      <div className={styles.fileLabel} title={file.Name} onClick={fileClickHandler(file)}>
        <FaFile/>
        <span>{file.Name}</span>
      </div>
    )
  }

  const fileClickHandler = (file) => () => {
    const {week_id, sheid} = topics.find(({sheid}) => sheid == topic)
    const data = {
      week: week_id,
      schedule: sheid,
      filename: file.Name
    }
    addFile(data, true)
    closeModal()
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      animationDuration={1}
      classNames={{modal: styles.topicModal}}
    >
      <Row className='mb-3'>
        <Form.Group>
          <Form.Label className={styles.formLabels}>Выберите тему:</Form.Label>
          <Form.Select
            name='topic'
            value={topic}
            onChange={handleChangeTopic}
          >
            <option value=''>Выбрать</option>
            {topics?.map(renderTopicOption)}
          </Form.Select>
        </Form.Group>
      </Row>
      <div className={cn(styles.topicFiles, {[styles.center]: !files?.length})}>
          {loading
            ? <Loader/>
            : files?.length
              ? files.map(renderFile)
              : <p className={styles.noFiles}>Файлы отсутствуют</p>
          }
      </div>
    </Modal>
  )
}