import React from 'react'
import styles from '../styles.module.scss'
import { FaFilePdf } from "react-icons/fa";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../../assets/fonts/fonts'
import Button from '../../../components/Button'
import { getInitials } from '../../../utils/smallHelpers';
import moment from 'moment';



export default function ExpertPDFPrint ({ data, organization, specializations, comission, totalPoints, comissionInfo }) {
  
  const getManagerFio = (manager) => `${manager.LastName} ${manager.FirstName} ${manager.Patronymic}`
  const getCategoryWord = (category) => category === 'первая' ? 'первой' : 'высшей'

  const positionGroup = specializations?.find(spec => spec.id === data?.position_id)?.positions_list
  const positionGroupFormatted = positionGroup ? `(${positionGroup.replace(',', ', ')})` : ''
  const statusText = data.status === 6 ? 'соответствует' : 'не соответствует'
  
  const createDocData = () => {
    
    let lastY = 10
  
    const getY = (indent, newValue) => {
      let newY = newValue ? newValue + indent : lastY + indent
      if (newY > doc.internal.pageSize.height - 20) {
        doc.addPage('a4', orientation)
        newY = 10
      }
      lastY = newY
      return newY
    }

    addFont(jsPDF.API)
    const orientation = 'l'
    const doc = new jsPDF({ orientation });
    doc.setFont("Montserrat");

    doc.setFontSize(10).setFont(undefined, 'bold')
    doc.text("Экспертное заключение об оценке профессиональной деятельности педагогического работника", 150, lastY, "center" )
    doc.text(positionGroupFormatted, 150, getY(5), "center" )
    
    
    doc.setFont(undefined, 'normal')
    !data.simple_cert && doc.text(`Необходимое количество баллов: ${data?.point || ''} `, 150, getY(5), "center" )


    doc.text(`ФИО: ${data.fio}`, 15, getY(10))
    doc.text(`Место работы: ${organization?.replace('\r\n', ' ')}`, 15, getY(5))
    doc.text(`Занимаемая должность: ${data.position}`, 15, getY(5))

    doc.autoTable({
      html: `#criterionsTable`,
      styles: { font: 'Montserrat', fontSize: 8 },
      headStyles: {
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0], 
        fillOpacity: 0.5 
      },
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
        fillColor: [255, 255, 255]
      },
      willDrawCell: function ({cell}) {
        if (cell.colSpan > 1) {
          cell.styles.valign = 'middle'
          cell.styles.halign = 'center'
          cell.styles.fontStyle = 'bold'
        }
        doc.setFillColor('white');
     },
      startY: getY(10)
    })

    !data.simple_cert && doc.setFont(undefined, 'bold').text(`Общее количество баллов: ${totalPoints || 0} / ${data.point || 0}`, 13, getY(10, doc.autoTableEndPosY()))
    doc.setFontSize(9).text('Общее заключение: на основании анализа портфолио профессиональной деятельности', 13, data.simple_cert ? getY(10, doc.autoTableEndPosY()) : getY(10))
    doc.setFont(undefined, 'normal').text(data.fio, 16, getY(9))
    doc.line(13, getY(1), 280, lastY)
    doc.setFontSize(7).text('(ФИО аттестуемого, должность)', 140, getY(3))
    
    doc.setFontSize(9)
    doc.text(statusText, 123, getY(9), 'center')
    doc.text(getCategoryWord(data.category), 205, lastY)

    doc.text('можно сделать вывод, что уровень его квалификации ________________________ требованиям, предъявляемым к _____________________ квалификационной категории.', 13, getY(1))
    doc.setFontSize(7).text('(соответствует / не соответствует)', 100, getY(3))
    doc.text('(первой / высшей)', 200, lastY)

    doc.setFontSize(9).setFont(undefined, 'bold').text('Подписи экспертов:', 13, getY(15))

    comission?.forEach((manager, ind) => {
      doc.text(manager.expert_fio, 13, getY(10))
      doc.line(70, lastY, 230, lastY)
      doc.text(manager.expert_fio, 245, lastY)
      doc.setFontSize(7).text('(должность, подпись)', 140, getY(3))
      doc.setFontSize(9)
    })

    doc.setFontSize(9).text(`Дата проведения экспертизы: ${moment(comissionInfo.date_to).format('DD.MM.YYYY')}`, 13, getY(10))
    
    doc.setFontSize(9).text("* По данному пункту наличие подтверждающих документов в портфолио является обязательным. При отсутствии подтверждающих документов экспертиза", 13, getY(15))
    doc.setFontSize(9).text("аттестационных материалов не проводится. Представленные материалы по пунктам 1.1, 2.1, 2.2, 2.3, 2.4 не должны дублироваться.", 13, getY(5))

    doc.addPage("a4", orientation);
    doc.deletePage(doc.getCurrentPageInfo().pageNumber);

    return doc
  }
  
  const toPdf = () => {
    const doc = createDocData()
    doc.save(`Заявление на аттестацию.pdf`)
  };
  
  return (
    <div className={styles.pdfBlock}>
      <Button style={{backgroundColor: 'rgb(223 7 8)'}} label='экспертное заключение' onClick={toPdf}>
        <FaFilePdf size={18}/>
      </Button>
    </div>
  )
}
