import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { Col, Form, Row, Table } from 'react-bootstrap'
import styles from '../styles.module.scss'
import { useFormik } from 'formik'
import ReactQuill from 'react-quill'
import Button from '../../../components/Button'
import cn from 'classnames'
import { strip } from '../../../utils/smallHelpers'
import { criterionFormValidationSchema } from '../../../assets/validation'
import { useDispatch, useSelector } from 'react-redux'
import { sendCriterion, updateCriterion } from '../../../actions/teacher'
import { useParams } from 'react-router-dom'
import FileBlock from '../../../components/FileBlock'
import { getOptions } from '../../../utils/getOptions'
import { statuses } from '../../../assets/const'
import { AiFillCaretDown } from "react-icons/ai";


export default function EditModal({
  open,
  onClose,
  data,
  draft,
  addMode,
  validPoints,
  teacherCanEdit,
  criteriaName,
  criteriaDocuments,
  criteriaLevels
}) {
  
  const dispatch = useDispatch()
  const { requestId } = useParams()
  const { expert, edu_expert } = useSelector(state => state.auth)
  const { comission, currentRequest } = useSelector(state => state.common)
  const { formLoading: loading } = useSelector(state => state.teacher)

  const [quillError, setQuillError] = useState({documents: false, description: false})
  const [minimize, setMinimize] = useState({top: true, bottom: true})


  const submitForm = (values) => {
    const documentsError = !strip(values.documents)
    // if (documentsError) {
    //   setQuillError(prev => ({...prev, documents: true}))
    //   return
    // }
    addMode ? createCriterion(values) : editCriterion(values)
  }


  const editCriterion = async (values) => {
    const {id, documents, description, points, commission_points, result_comment, expert_comment, status, files} = values
    const payload = expert
      ? {points, result_comment, status}
        : edu_expert
            ? { commission_points, expert_comment, status}
            : { documents, commission_points, description, files }
    const response = await dispatch(updateCriterion(payload, requestId, id))
    if (!response) return;
  
    onClose()
  }
  
  const createCriterion = async (values) => {
    const {documents, description, criteria, commission_points, files} = values
    const payload = { documents, commission_points, criteria, description, files }
    const response = await dispatch(sendCriterion(payload, requestId))
    if (!response) return;

    onClose()
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    setTouched
  } = useFormik({
    initialValues: {...data, files: data.files_json || []},
    enableReinitialize: true,
    // validationSchema: criterionFormValidationSchema,
    onSubmit: submitForm
  })
  
  const closeModal = () => {
    handleReset()
    onClose()
  }

  const handleAddFile = (fileObject, choiceMode) => {
    const {lesson_id, parallel, file} = fileObject
    if (!file?.length && !choiceMode) return;
    
    let newFile
    const existFiles = values.files || []
    
    if (choiceMode) {
      newFile = fileObject
    } else {
      const filename = file[0].name
      newFile = {file: file[0], filename, lesson_id, parallel}
    }
    
    const filesArr  = [...existFiles, newFile]
    const filesValue = filesArr.length ? filesArr : null
    setFieldValue('files', filesValue)
  }

  const handleSetPoints = (e) => {
    handleChange(e)
    if (!expert && !edu_expert) return;
    const statusValue = e.target.value ? (expert ? 2 : 3) : 0
    setFieldValue('status', statusValue)
  }
  
  const handleSetTouched = (name) => {
    setTouched({...touched, [name]: true})
  }

  const onDeleteFile = (name, index) => {
    const filesList = [...values[name]]
    filesList.splice(index, 1)
    const filesValue = filesList.length ? filesList : null
    setFieldValue(name, filesValue)
  }

  const handleQuillChange = (name, value) => {
    setFieldValue(name, value)
    const errorValue = !strip(value)
    // setQuillError(prev => ({...prev, [name]: errorValue}))
  }
  
  const minimizeClick = (name) => () => setMinimize(prev => ({...prev, [name]: !prev[name]}))

  const verified = comission.values?.length >= 4
  const criterionDescription = criteriaDocuments ? `(${criteriaDocuments})` : ''
  const eduFieldsDisabled = !edu_expert || currentRequest.status !== 2
  
  return (
    <Modal
        open={open}
        onClose={onClose}
        classNames={{ modal: styles.criterionModal }}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}
        animationDuration={1}
      >
          <h4>{criteriaName}</h4>
          
          <div className={cn(styles.criterionDescription, {[styles.minimizeBlock]: minimize.top})}>
            {criterionDescription}
          </div>
          <MinimizeButton minimize={minimize.top} onClick={minimizeClick('top')}/>

          <Form className='mt-3'>
            {!!data.level_json?.length &&
            <Row className='mb-3'>
              
              <Form.Group>
                <Form.Label className={styles.formLabels}>Выберите опцию:</Form.Label>
                <Form.Select
                  name='level'
                  value={values.level}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.level && errors.level}
                >
                  <option value=''>Выбрать</option>
                  {criteriaLevels.map(level =>
                    <option key={level.level_id} value={level.level_id}>{level.level_name}</option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.cert_year}</Form.Control.Feedback>
              </Form.Group>

            </Row>}
            <Row className='mb-3'>
              <Form.Label className={cn(styles.formLabels, {
                [styles.errorLabel]: quillError.documents
              })}>
                Наличие подтверждающих документов в портфолио:
              </Form.Label>
              <ReactQuill
                readOnly={expert || edu_expert || !teacherCanEdit}
                className={cn(styles.quill, {
                  [styles.error]: quillError.documents,
                  [styles.quillDisabled]: expert || edu_expert || !teacherCanEdit
                })}
                value={values.documents}
                onChange={value => handleQuillChange('documents', value)}
              />
            </Row>
            <Row className='mb-3'>
              <FileBlock
                choiceButtonVisible={data.criteria === 692 || data.criteria === 1}
                filesList={values.files}
                addFile={handleAddFile}
                onDelete={onDeleteFile}
                disabled={expert || edu_expert || !teacherCanEdit}
              />
            </Row>

            <Row className={`${styles.pointsRow} mt-3 mb-3`}>
              <Col md={3}>
                <Form.Group as={Col} >
                  <Form.Label className={styles.formLabels}>Баллы комиссии ОО:</Form.Label>
                  <Form.Control
                    name='commission_points'
                    type='number'
                    disabled={eduFieldsDisabled || currentRequest.simple_cert}
                    value={values.commission_points}
                    onChange={handleSetPoints}
                    onBlur={handleBlur}
                    isInvalid={touched.commission_points && errors.commission_points}
                  />
                  <Form.Control.Feedback type="invalid">{errors.points}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group as={Col}>
                  <Form.Label className={styles.formLabels}>Баллы эксперта:</Form.Label>
                  <Form.Control
                    name='points'
                    type='number'
                    value={values.points}
                    onChange={handleSetPoints}
                    onBlur={handleBlur}
                    isInvalid={touched.points && errors.points}
                    disabled={!expert || verified || currentRequest.simple_cert}
                  />
                  <Form.Control.Feedback type="invalid">{errors.points}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <MinimizeButton minimize={minimize.bottom} onClick={minimizeClick('bottom')}/>
            
            <div className={cn(styles.bottomPart, {[styles.minimizeBlock]: minimize.bottom})}>
              <Row className='mb-3'>
                <Form.Label className={styles.formLabels}>Примечание:</Form.Label>
                <ReactQuill
                  readOnly={expert || edu_expert || !teacherCanEdit}
                  className={cn(styles.quill, {
                    [styles.quillDisabled]: expert || edu_expert || !teacherCanEdit
                  })}
                  value={values.description}
                  onChange={value => handleQuillChange('description', value)}
                />
              </Row>
              {!draft &&
                <>
                    {/* <Row>
                      <Form.Group>
                        <Form.Label className={styles.formLabels}>Статус:</Form.Label>
                        <Form.Select
                          name='status'
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.status && errors.status}
                          disabled={(!expert && eduFieldsDisabled) || verified}
                        >
                          {getOptions(statuses)}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                      </Form.Group>
                    </Row>                 */}
                    
                    <Row className='mt-3'>
                      <Form.Group>
                        <Form.Label className={styles.formLabels}>Комментарий (комиссия ОО):</Form.Label>
                        <Form.Control
                          name='expert_comment'
                          as='textarea'
                          rows={3}
                          value={values.expert_comment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.expert_comment && errors.expert_comment}
                          disabled={eduFieldsDisabled}
                        />
                        <Form.Control.Feedback type="invalid">{errors.result_comment}</Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className='mt-3'>
                      <Form.Group>
                        <Form.Label className={styles.formLabels}>Комментарий (эксперт):</Form.Label>
                        <Form.Control
                          name='result_comment'
                          as='textarea'
                          rows={3}
                          value={values.result_comment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.result_comment && errors.result_comment}
                          disabled={!expert || verified}
                        />
                        <Form.Control.Feedback type="invalid">{errors.result_comment}</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                </>
              }
            </div>

            <div className={styles.criterionButtons}>
              <Button
                type='button'
                label='Сохранить'
                onClick={handleSubmit}
                submitting={loading}
                disabled={!expert && !edu_expert && !teacherCanEdit}
              />
              <Button
                type='button'
                label='Отмена'
                onClick={closeModal}
              />
            </div>
          </Form>
      </Modal>
  )
}



const MinimizeButton = ({ onClick, minimize }) => (
  <div className={cn(styles.minimizeButtonBlock, {[styles.reverse]: !minimize})}>
    <button type='button' onClick={onClick}>
      <AiFillCaretDown/>
      {minimize ? 'развернуть' : 'свернуть' }
    </button>
  </div>
)